//

import React from "react"
import styled from "styled-components"
import { Container } from "react-bootstrap"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import { Match, navigate } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"
import { getNodesFromQuery } from "../service/helper"
import queryString from "querystring"

import DynamicContactForm from "../components/DynamicContactForm"

// component for contact form page
const contactFormPage = props => {
  // query questionnaire data from sanity
  const request = graphql`
    query QuestionnaireQuery {
      standardQuestionnaire: sanityQuestionnaire(
        _id: { eq: "questionnaireStandard" }
      ) {
        id
        _id
        title
        inputs {
          ... on SanityNormalInput {
            _key
            _type
            name
            placeholder
            required
            type
          }
          ... on SanitySelectInput {
            _key
            _type
            type
            required
            options
            name
            label
          }
        }
      }

      questionnaires: allSanityQuestionnaire {
        edges {
          node {
            id
            _id
            title
            inputs {
              ... on SanityNormalInput {
                _key
                _type
                name
                placeholder
                required
                type
              }
              ... on SanitySelectInput {
                _key
                _type
                type
                required
                options
                name
                label
              }
            }
          }
        }
      }
    }
  `
  return (
    <Layout showHeader showLogo>
      <SEO title="Kontakt" />
      <MyFormContainer>
        <h2 style={{ flexBasis: "100%" }}>Schreibe uns eine Nachricht!</h2>
        <div className="form-content-container">
          <span>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </span>
          <Match path="/contactFormDynamic">
            {props => {
              console.log(
                "queryString",
                queryString.parse(props.location.search)
              )
              const { formId } = queryString.parse(props.location.search)

              if (!props.match) {
                navigate("/")
                return null
              }

              return (
                <StaticQuery
                  query={request}
                  render={data => {
                    const questionnaires = getNodesFromQuery(
                      data.questionnaires
                      // filter out standard questionnaire
                    ).filter(questionnaire => {
                      //console.log("all questionnaires", questionnaire)
                      return questionnaire._id !== "questionnaireStandard"
                    })
                    // the standard Questionnaire, seperated from other questionnaires
                    const standardQuestionnaire = data.standardQuestionnaire

                    // console.log(
                    //   "standard",
                    //   standardQuestionnaire,
                    //   "others",
                    //   questionnaires
                    // )

                    return (
                      <DynamicContactForm
                        preselect={formId}
                        standardFields={standardQuestionnaire.inputs}
                        questionnaires={questionnaires}
                      />
                    )
                  }}
                />
              )
            }}
          </Match>
        </div>
      </MyFormContainer>
    </Layout>
  )
}

// container for the contact form (use bootstrap container, add styles)
const MyFormContainer = styled(Container)`
  flex: 0 0 50%;
  background-color: #eeeeee;
  height: 100%;
  padding: 30px;
  margin: 50px auto;
  border: 1px solid #888888;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .form-content-container {
    display: flex;
  }
  /* mobile styling */
  @media (max-width: ${props => props.theme.mobile}) {
    flex-direction: column;
    .form-content-container {
      flex-direction: column;
    }
  }
`

export default contactFormPage
